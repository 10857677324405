<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">HOME SLIDERS</h1>
    </div>
    <MainSlider />
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
import MainSlider from "@/components/HomeSliderForms/MainSlider";

export default {
  name: "HomeSliders",
  components: {
    MainLayout,
    MainSlider,
  },
};
</script>

<style>
</style>