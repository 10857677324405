<template>
  <div class="card mt-3">
    <div class="card-body">
      <h4 class="card-title">MAIN SLIDER</h4>
      <form>
        <div class="form-group">
          <select
            class="form-control"
            v-model="main_slider_new.type"
            name=""
            id=""
          >
            <option value="video">Video</option>
            <option value="picture">Picture</option>
          </select>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            v-model="main_slider_new.heading"
            placeholder="Heading"
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            v-model="main_slider_new.paragraph"
            placeholder="Paragraph"
          />
        </div>
        <div class="form-group d-inline-block">
          <input
            @change="add_file_to_main_slider"
            ref="main_slider_input"
            type="file"
            class="form-control-file"
            id="exampleFormControlFile1"
          />
        </div>

        <button
          :disabled="!main_slider_new.type || !main_slider_new.file"
          @click.prevent="add_main_slide(main_slider_new)"
          class="btn btn-block btn-primary"
        >
          Add
        </button>
      </form>
      <div class="mt-5 d-flex flex-wrap" style="gap: 10px">
        <div
          class="w-25 preview flex flex-column"
          v-for="(slide, i) in main_slider"
          :key="i"
        >
          <video
            v-if="slide.type === 'video'"
            :src="slide.url"
            autoplay="true"
            muted="true"
            loop
            class="w-100 d-inline-block"
          />
          <img
            v-if="slide.type === 'picture'"
            :src="slide.url"
            class="w-100 d-inline-block"
          />
          <button @click="remove_main_slide(i)">
            <span> &#x02A2F; </span>
          </button>
          <div
            v-if="slide.heading || slide.paragraph"
            class="card p-2 align-self-end"
          >
            <span class="d-block">{{ slide.heading }}</span>
            <span class="d-block">{{ slide.paragraph }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-3">
    <div class="card-body">
      <h4 class="card-title">FOOTER SLIDER</h4>
      <form>
        <div class="form-group">
          <label for="exampleFormControlFile1">Desktop</label>
          <input
            @change="add_file_to_footer_slider($event, 'desktop')"
            ref="footer_slider_desktop_input"
            type="file"
            class="form-control-file"
            id="exampleFormControlFile1"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlFile1">Mobile</label>
          <input
            @change="add_file_to_footer_slider($event, 'mobile')"
            ref="footer_slider_mobile_input"
            type="file"
            class="form-control-file"
            id="exampleFormControlFile1"
          />
        </div>

        <button
          :disabled="!footer_slider_new.desktop || !footer_slider_new.mobile"
          @click.prevent="add_footer_slide(footer_slider_new)"
          class="btn btn-block btn-primary"
        >
          Add
        </button>
      </form>
      <div
        class="mt-5 d-flex flex-wrap"
        style="gap: 10px"
        v-for="(slide, i) in footer_slider"
        :key="i"
      >
        <div class="w-25 preview flex flex-column">
          <img :src="slide.desktop_url" class="w-100 d-inline-block" />
          <button @click="remove_footer_slide(i)">
            <span> &#x02A2F; </span>
          </button>
        </div>
        <div class="w-25 preview flex flex-column">
          <img :src="slide.mobile_url" class="w-100 d-inline-block" />
          <button @click="remove_footer_slide(i)">
            <span> &#x02A2F; </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      main_slider_new: {
        new: true,
        type: "video",
        file: null,
        url: "",
        heading: "",
        paragraph: "",
      },
      footer_slider_new: {
        desktop: null,
        mobile: null,
        new: true,
        desktop_url: "",
        mobile_url: "",
      },
      main_slider: [],
      footer_slider: [],
    };
  },
  methods: {
    async setImagePreview(file, screen) {
      const fr = new FileReader();

      const src = fr.readAsDataURL(file);
      fr.onload = () => {
        if (!screen) {
          this.main_slider_new.url = fr.result;
        } else {
          this.footer_slider_new[`${screen}_url`] = fr.result;
        }
      };
    },
    async saveSlides() {
      try {
        for (const item of this.main_slider) {
          if (item.new) {
            item.file = await this.$store.dispatch("uploadImage", item.file);
          }
          delete item.url;
          delete item.new;
        }

        for (const item of this.footer_slider) {
          if (item.new) {
            item.desktop = await this.$store.dispatch(
              "uploadImage",
              item.desktop
            );
            item.mobile = await this.$store.dispatch(
              "uploadImage",
              item.mobile
            );
          }
          delete item.url;
          delete item.new;
          delete item.desktop_url;
          delete item.mobile_url;
        }

        const res = await axios.patch(
          "/api/v1/settings/home-slides",
          { main_slider: this.main_slider, footer_slider: this.footer_slider },
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status === 200) {
          this.getSliders();
          this.$refs.main_slider_input.value = null;
          this.$refs.footer_slider_desktop_input.value = null;
          this.$refs.footer_slider_mobile_input.value = null;
          this.main_slider_new = {
            new: true,
            type: "video",
            file: null,
            url: "",
            heading: "",
            paragraph: "",
          };
          this.footer_slider_new = {
            desktop: null,
            mobile: null,
            new: true,
            desktop_url: "",
            mobile_url: "",
          };
        }
      } catch (e) {
        console.log(e);
      }
    },
    add_file_to_main_slider(e) {
      this.main_slider_new.file = e.target.files[0];
      this.setImagePreview(this.main_slider_new.file);
    },
    add_file_to_footer_slider(e, type) {
      this.footer_slider_new[type] = e.target.files[0];
      this.setImagePreview(this.footer_slider_new[type], type);
    },
    remove_main_slide(index) {
      this.main_slider = this.main_slider.filter((item, i) => i !== index);
      this.saveSlides();
    },
    remove_footer_slide(index) {
      this.footer_slider = this.footer_slider.filter((item, i) => i !== index);
      this.saveSlides();
    },
    add_main_slide(item) {
      this.main_slider.push(item);
      this.saveSlides();
    },
    add_footer_slide(item) {
      this.footer_slider.push(item);
      this.saveSlides();
    },
    async getSliders() {
      try {
        const res = await axios.get("/api/v1/settings");

        if (res.status === 200) {
          this.main_slider = res.data.settings.home_slides.main_slider.map(
            (item) => {
              item.url = `/uploads/${item.file}`;
              return item;
            }
          );
          this.footer_slider = res.data.settings.home_slides.footer_slider.map(
            (item) => {
              item.desktop_url = `/uploads/${item.desktop}`;
              item.mobile_url = `/uploads/${item.mobile}`;
              return item;
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getSliders();
  },
};
</script>

<style scoped>
.preview {
  position: relative;
}

.preview > button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 1px;
  font-size: 25px;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 1000px;
}

.preview > button > span {
  display: inline-block;
  margin-top: -4px;
}
</style>